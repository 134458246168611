import { I18nProvider } from "@lingui/react"
import { WrapPageElementBrowserArgs } from "gatsby"
import React from "react"

import { i18n, LocalizedPageContext } from "./src/locales/lingui"
import { LocationState, PageProvider } from "./src/utils/contexts/page-context"

export function wrapPageElement({
  element,
  props: {
    location: { pathname, search, state },
    pageContext,
  },
}: WrapPageElementBrowserArgs<
  unknown,
  LocalizedPageContext,
  LocationState
>): React.ReactNode {
  const { locale = "en", localeCatalog } = pageContext

  i18n.loadAndActivate({ locale, messages: localeCatalog })

  return (
    <I18nProvider i18n={i18n}>
      <PageProvider pathname={pathname} search={search} state={state}>
        {element}
      </PageProvider>
    </I18nProvider>
  )
}
